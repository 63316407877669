import { Button, IconButton, LinearProgress, TextField, Stepper, Step, StepLabel } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ReplayIcon from "@mui/icons-material/Replay";
import { green } from "@mui/material/colors";
import { useRef, useState } from "react";
import { sha256 } from "js-sha256";
import { ADMIN_URL } from "../constants";
import Cookies from "js-cookie";

const Filter = require("bad-words");
const uploadSteps = 5;

const Home = ({ socket, showSnackbar, setGameSettings, savePassword }) => {
	const [openJoin, setOpenJoin] = useState(false);
	const [openNew, setOpenNew] = useState(false);
	const [noNamesFile, setNoNamesFile] = useState(null);
	const [noNamesFilename, setNoNamesFilename] = useState(null);
	const [withNamesFilename, setWithNamesFilename] = useState(null);
	const [withNamesFile, setWithNamesFile] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [showPasswordAlert, setShowPasswordAlert] = useState(false);
	const [showUploadAlert, setShowUploadAlert] = useState(false);
	const [showGameidAlert, setShowGameidAlert] = useState(false);
	const [currentUploadStep, setCurrentUploadStep] = useState(0);
	const [joinGameState, setJoinGameState] = useState(null);
	const [activeStep, setActiveStep] = useState(0);

	const noNamesInput = useRef(null);
	const withNamesInput = useRef(null);

	const noNameButtonSx = {
		...(noNamesFile !== null && {
			bgcolor: green[500],
			"&:hover": {
				bgcolor: green[700],
			},
		}),
	};

	const withNameButtonSx = {
		...(withNamesFile !== null && {
			bgcolor: green[500],
			"&:hover": {
				bgcolor: green[700],
			},
		}),
	};

	function handleFileChange(e) {
		let extension = e.target.files[0].name.split(".").slice(-1)[0];

		let filename = e.target.files[0].name;
		if (filename.length > 20) {
			filename = filename.slice(0, 17 - extension.length);
			filename += "..." + extension;
		}

		switch (e.target.id) {
			case "noNamesInput":
				if (extension !== "zip") {
					showSnackbar(
						"error",
						"Alleen .zip bestanden mogen geüpload worden"
					);
					setNoNamesFile(null);
					setNoNamesFilename(null);
				} else {
					setNoNamesFile(e.target.files[0]);
					setNoNamesFilename(filename);
					setShowUploadAlert(false);
				}
				break;
			case "withNamesInput":
				if (extension !== "zip") {
					showSnackbar(
						"error",
						"Alleen .zip bestanden mogen geüpload worden"
					);
					setWithNamesFile(null);
					setWithNamesFilename(null);
				} else {
					setWithNamesFile(e.target.files[0]);
					setWithNamesFilename(filename);
				}
				break;
			default:
				console.log("Unkown file upload");
				break;
		}
	}

	function handleUploadBtnClick(type) {
		if (!uploading) {
			document.getElementById(type).click();
		}
	}

	function resetUpload(type) {
		if (!uploading) {
			switch (type) {
				case "noNames":
					setNoNamesFile(null);
					break;
				case "withNames":
					setWithNamesFile(null);
					break;
				default:
					console.log("Unkown file reset");
					break;
			}
		}
	}

	function handleCloseJoin(choice) {
		if (choice) {
			let gameID = document.getElementById("gameID").value;
			let password = document.getElementById("password").value;

			if (gameID === "" || gameID === null || gameID === undefined) {
				setShowGameidAlert(true);
			} else if (
				password === "" ||
				password === null ||
				password === undefined
			) {
				setShowPasswordAlert(true);
			} else {
				setShowGameidAlert(false);
				setShowPasswordAlert(false);
				showSnackbar("info", `Verbinding maken met ${gameID}`);

				joinGame(false, gameID, password).then((value) => {
					if (value !== "error") {
						showSnackbar("success", "Verbonden!");
						setOpenJoin(false);
						console.log(value);
						setGameSettings(value);
						Cookies.set("gameID", gameID, { expires: 7 });
					}
				});
			}
		} else {
			setOpenJoin(false);
		}
	}

	function handleCloseNew(choice) {
		if (choice === "accept") {
			let password = document.getElementById("password").value;

			if (
				password === "" ||
				password === null ||
				password === undefined
			) {
				setShowPasswordAlert(true);
			} else if (noNamesFile === null || noNamesFile === undefined) {
				setShowUploadAlert(true);
			} else {
				setShowPasswordAlert(false);
				setShowUploadAlert(false);
				uploadFiles(password);
			}
		} else if (choice === "cancel") {
			setOpenNew(false);
			resetDialogs();
		} else if (!uploading) {
			setOpenNew(false);
			resetDialogs();
		}
	}

	function resetDialogs() {
		setNoNamesFile(null);
		setNoNamesFilename(null);
		setWithNamesFile(null);
		setWithNamesFilename(null);
		setUploading(false);
		setJoinGameState(null);
		setShowGameidAlert(false);
		setShowPasswordAlert(false);
		setShowUploadAlert(false);
	}

	async function uploadFiles(password) {
		setUploading(true);
		setCurrentUploadStep(0);

		let tempGameID;
		let filter = new Filter();
		const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
		let gameIDValid = false;

		while (!gameIDValid) {
			let counter = 0;
			tempGameID = "";
			while (counter < 4) {
				tempGameID += characters.charAt(Math.floor(Math.random() * 26));
				counter += 1;
			}

			if (!filter.isProfane(tempGameID)) {
				gameIDValid = true;
			}
		}

		setJoinGameState("Spel aanmaken op server");
		await new Promise(function (resolve) {
			setTimeout(() => {
				resolve(true);
			}, 500);
		});

		let result = await joinGame(true, tempGameID, password);

		if (result === "error") {
			return false;
		}

		let step = 1;
		setCurrentUploadStep(step);
		setJoinGameState("Foto's uploaden");

		let uploadPromise = new Promise(function (resolve) {
			const formData = new FormData();

			formData.append("gameID", tempGameID);
			formData.append("noNames", noNamesFile);

			if (withNamesFile !== null) {
				formData.append("withNames", withNamesFile);
				console.log("With names uploaded");
			}

			const xhr = new XMLHttpRequest();
			xhr.open("POST", `${ADMIN_URL}/upload`);
			xhr.responseType = "text";
			xhr.setRequestHeader("uploadType", "normal");

			xhr.onload = () => {
				console.log(xhr.response);
				if (xhr.status === 200) {
					resolve({ response: "success" });
				} else {
					resolve({ response: "error", msg: JSON.parse(xhr.response.msg) });
				}
			};

			xhr.upload.onprogress = (e) => {
				if (e.lengthComputable) {
					let progress = e.loaded / e.total;
					console.log(`Uploading: ${progress * 100}`);

					setCurrentUploadStep(step + progress);
					setJoinGameState(
						`Foto's uploaden (${Math.round(
							(e.loaded / e.total) * 100
						)}%)`
					);
				}
			};

			xhr.onerror = () => {
				console.log(xhr);
				let msg = "Error bij het uploaden van de foto's";
				resolve({ response: "error", msg: msg });
			};

			xhr.send(formData);
		});

		socket.on("newGameStatus", (msg) => {
			let jsonMsg = JSON.parse(msg);

			step++;
			setCurrentUploadStep(step);
			setJoinGameState(jsonMsg.step);
		});

		socket.on("gameSettings", (msg) => {
			let jsonMsg = JSON.parse(msg);

			setGameSettings(jsonMsg);
			console.log(jsonMsg);

			showSnackbar("success", "Spel aanmaken gelukt");
			Cookies.set("gameID", jsonMsg.gameID, { expires: 7 });

			resetDialogs();
			setOpenNew(false);

			socket.off("newGameStatus");
			socket.off("gameSettings");
		});

		result = await uploadPromise;

		console.log(result);
		if (result.response === "error") {
			showSnackbar("error", result.msg);
			resetDialogs();
			return;
		}
	}

	async function joinGame(newGame, ID, password) {
		let promise = new Promise(function (resolve) {
			let msg = {
				newGame: newGame,
				gameID: ID,
				password: sha256(password),
			};

			savePassword(sha256(password));
			socket.emit("joinGameAdmin", JSON.stringify(msg));

			socket.on("gameJoined", (msg) => {
				let jsonMsg = JSON.parse(msg);

				if (jsonMsg.gameID !== ID) {
					console.log(`${jsonMsg.gameID} | ${ID}`);
					showSnackbar("error", "Server error, probeer het opnieuw");
					resetDialogs();
					setOpenNew(false);
					setOpenJoin(false);

					resolve("error");
				} else {
					if (newGame) {
						resolve("success");
					} else {
						resolve(jsonMsg);
					}
				}

				socket.off("gameJoined");
				socket.off("joinGameError");
			});

			socket.on("joinGameError", (msg) => {
				let jsonMsg = JSON.parse(msg);
				showSnackbar("error", jsonMsg.msg);

				resetDialogs();
				setOpenJoin(false);
				setOpenNew(false);
				resolve("error");

				socket.off("gameJoined");
				socket.off("joinGameError");
			});
		});

		return await promise;
	}

	return (
		<div
			style={{
				width: "fit-content",
				height: "fit-content",
				margin: "0 auto",
			}}>
			<Button
				variant="contained"
				sx={{ margin: "20px 20px 20px 20px" }}
				onClick={() => setOpenNew(true)}>
				Nieuw spel
			</Button>
			<Button
				variant="contained"
				sx={{ margin: "20px 20px 20px 20px" }}
				onClick={() => setOpenJoin(true)}>
				Deelnemen
			</Button>

			<Dialog open={openJoin} onClose={() => handleCloseJoin(false)}>
				<DialogTitle>Deelnemen aan spel</DialogTitle>
				<DialogContent>
					<DialogContentText>Voer de spelcode in.</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="gameID"
						label="Spelcode"
						type="text"
						variant="standard"
					/>
					<br />
					<span
						style={{
							display: `${showGameidAlert ? "inline" : "none"}`,
						}}>
						Vul een spelcode in
					</span>
					<br />
					<TextField
						margin="dense"
						id="password"
						label="Wachtwoord"
						type="password"
						variant="standard"
					/>
					<br />
					<span
						style={{
							display: `${showPasswordAlert ? "inline" : "none"}`,
						}}>
						Vul een wachtwoord in
					</span>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleCloseJoin(false)}>
						Annuleren
					</Button>
					<Button
						onClick={() => handleCloseJoin(true)}
						variant="contained">
						Deelnemen
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={openNew} onClose={() => handleCloseNew(false)}>
				<DialogTitle>Nieuw spel maken</DialogTitle>
				<Stepper activeStep={activeStep}>
					
				</Stepper>
				<DialogContent>
					<DialogContentText sx={{ marginBottom: "10px" }}>
						Eerst moet een wachtwoord ingevuld worden om de
						admin-pagina te beschermen.
					</DialogContentText>
					<DialogContentText>
						Vervolgens kunnen de foto's worden geüpload. Let op dat
						de volgorde van de foto's met en zonder straatnamen
						hetzelfde is. Anders corresponderen de nummers van de
						foto's op de admin-pagina niet met wat de spelers zien.
					</DialogContentText>
					<TextField
						onChange={() => setShowPasswordAlert(false)}
						margin="dense"
						id="password"
						label="Wachtwoord"
						type="password"
						variant="standard"
						error={showPasswordAlert}
						helperText={
							showPasswordAlert ? "Vul een wachtwoord in" : null
						}
						sx={{ marginBottom: "10px" }}
					/>
					<br />
					<Button
						variant="contained"
						sx={noNameButtonSx}
						disabled={uploading}
						color={showUploadAlert ? "error" : "primary"}
						startIcon={
							noNamesFile == null ? <CloudUploadIcon /> : null
						}
						onClick={() => handleUploadBtnClick("noNamesInput")}>
						{noNamesFile == null
							? "Foto's zonder straatnamen"
							: noNamesFilename}
					</Button>
					{noNamesFile == null ? null : (
						<IconButton
							onClick={() => resetUpload("noNames")}
							disabled={uploading}>
							<ReplayIcon />
						</IconButton>
					)}
					<br />
					<span
						style={{
							display: `${showUploadAlert ? "inline" : "none"}`,
							color: "red",
						}}>
						Upload foto's zonder straatnamen
					</span>
					<br
						style={{
							display: `${showUploadAlert ? "inline" : "none"}`,
						}}
					/>
					<form ref={noNamesInput}>
						<input
							id="noNamesInput"
							type="file"
							accept=".zip"
							onChange={(e) => handleFileChange(e)}
							style={{ display: "none" }}
						/>
					</form>
					<br />
					<Button
						variant="contained"
						startIcon={
							withNamesFile == null ? <CloudUploadIcon /> : null
						}
						disabled={uploading}
						sx={withNameButtonSx}
						onClick={() => handleUploadBtnClick("withNamesInput")}>
						{withNamesFile == null
							? "Foto's met straatnamen (optioneel)"
							: withNamesFilename}
					</Button>
					{withNamesFile == null ? null : (
						<IconButton
							onClick={() => resetUpload("withNames")}
							disabled={uploading}>
							<ReplayIcon />
						</IconButton>
					)}
					<form ref={withNamesInput}>
						<input
							id="withNamesInput"
							type="file"
							accept=".zip"
							onChange={(e) => handleFileChange(e)}
							style={{ display: "none" }}
						/>
					</form>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => handleCloseNew("cancel")}>
						{uploading ? "Stoppen" : "Annuleren"}
					</Button>
					<Button
						variant="contained"
						onClick={() => handleCloseNew("accept")}
						disabled={uploading}>
						Spel maken
					</Button>
				</DialogActions>
				<div
					style={{
						textAlign: "center",
						display: `${uploading ? "inline" : "none"}`,
						marginBottom: "10px",
					}}>
					{joinGameState}
				</div>
				<LinearProgress
					value={Math.round((currentUploadStep / uploadSteps) * 100)}
					variant="determinate"
					sx={{
						display: `${uploading ? null : "none"}`,
					}}></LinearProgress>
			</Dialog>
		</div>
	);
};

export default Home;
