import "./App.css";
import { useState, useEffect } from "react";
import { Paper, ThemeProvider, createTheme, Snackbar, Alert, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { colors } from "./constants";
import Streets from "./components/Streets";
import { socket } from "./socket";
import Cookies from "js-cookie";
import Home from "./components/Home";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const [points, setPoints] = useState([0, 0, 0]);
  const [gameID, setGameID] = useState(null);
  const [gameSettings, setGameSettings] = useState(null);
  const [password, setPassword] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("");
  const [snackbarContent, setSnackbarContent] = useState("");
  const [openStopDialog, setOpenStopDialog] = useState(false);

  useEffect(() => {
    document.title = "Stratenjagen admin";
    socket.on("claimUpdate", (msg) => {
      let jsonMsg = JSON.parse(msg);
      setGameSettings(jsonMsg);
    })

    socket.on("connect", () => {
      console.log("Websocket connected")
    });

    socket.on("points", (msg) => {
      let jsonMsg = JSON.parse(msg);

      setPoints(jsonMsg);
    });

    socket.on("claimError", (msg) => {
      showSnackbar("error", msg);
    });

    socket.on("gameStopped", () => {
      showSnackbar("info", "Spel gestopt");
      resetGame();
    })

    setGameID(Cookies.get("gameID"));
    if(Cookies.get("gameID")){
      
    }

    return () => {
      socket.off("streets");
      socket.off("connect");
    };
  }, []);

  function claimStreet(info) {
    console.log("Claiming street");

    info.password = password;
    socket.emit("newClaim", JSON.stringify(info));
  }

  function showSnackbar(severity, content) {
    setSnackbarSeverity(severity);
    setSnackbarContent(content);
    setOpenSnackbar(true);
  }

  function handleSnackbarClose() {
    setOpenSnackbar(false);
  }

  function resetGame() {
    setPassword(null);
    setPoints([0, 0, 0]);
    setGameID(null);
    setGameSettings(null);
    Cookies.remove("gameID")
  }

  function stopGame(action) {
    setOpenStopDialog(false);

    switch (action) {
      case "leave":
        resetGame();
        break;
      case "stop":
        let msg = {
          gameID: gameSettings.gameID,
          password: password
        }

        console.log("Stopping game");
        console.log(msg);
        socket.emit("stopGame", JSON.stringify(msg));

        socket.on("stopGameError", (msg) => {
          showSnackbar("error", msg);

          socket.off("stopGameError");
        })
        break;
      default:
        console.log("Unexpected leave game state");
    }
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <Snackbar open={openSnackbar} autoHideDuration={5000} onClose={handleSnackbarClose} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarContent}
        </Alert>
      </Snackbar>
      <Dialog open={openStopDialog} onClose={() => stopGame("cancel")}>
        <DialogTitle>Spel stoppen</DialogTitle>
        <DialogContent>
          <DialogContentText>Weet je zeker dat je het spel wil stoppen? Als je het spel stopt, worden alle foto's verwijderd. Het spel kan dan niet opnieuw gestart worden. Als je dat niet wil, kan je ook het spel verlaten. Je kan dan later weer deelnemen aan het spel.</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => stopGame("cancel")}>Annuleren</Button>
          <Button onClick={() => stopGame("leave")} variant="contained">Spel verlaten</Button>
          <Button onClick={() => stopGame("stop")} variant="contained" color="error">Spel stoppen</Button>
        </DialogActions>
      </Dialog>
      <div
        style={{
          height: "fit-content",
          width: "100%",
          border: "none",
          borderRadius: "0px",
        }}>
        <div
          style={{
            color: colors.on_primary,
            backgroundColor: colors.primary,
            height: "40px",
            paddingBottom: "15px",
            fontWeight: "700",
            fontSize: "40px",
            textAlign: "center",
            border: "none",
            borderRadius: "0px",
            marginBottom: "10px",
          }}>
          {gameSettings == null || gameSettings === undefined ? "Stratenjagen admin" : gameSettings.gameID}
        </div>
        {gameSettings == null || gameSettings === undefined ? (
          <Home showSnackbar={showSnackbar} socket={socket} setGameSettings={(settings) => setGameSettings(settings)} savePassword={(value) => setPassword(value)} />
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Button variant="contained" color="error" sx={{ marginBottom: '10px' }} onClick={() => setOpenStopDialog(true)}>Spel stoppen</Button>
            <Streets
              settings={gameSettings}
              claimStreet={(info) => claimStreet(info)}
              points={points}
            />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}

export default App;
