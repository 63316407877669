import { Paper, TextField, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ModalImage from "react-modal-image";
import { ADMIN_URL } from "../constants";
import { useEffect, useState } from "react";

const StreetRow = ({
	photo,
	claimPhoto,
	gameID,
	number,
	withNamesUploaded,
}) => {
	const [nameClaimedBy, setNameClaimedBy] = useState(null);
	const [photoClaimedBy, setPhotoClaimedBy] = useState(null);

	useEffect(() => {
		setNameClaimedBy(photo.nameClaimedBy);
		setPhotoClaimedBy(photo.photoClaimedBy);
	}, []);

	function sendInfo() {
		let info = {
			gameID: gameID,
			password: "",
			photoID: photo.photoID,
			photoClaimedBy: photoClaimedBy,
			nameClaimedBy: nameClaimedBy,
		};

		console.log(`Claiming photo ${photo.photoID}`);
		console.log(info);
		claimPhoto(info);
	}

	return (
		<Paper
			sx={{
				width: "fit-content",
				height: "fit-content",
				margin: "10px auto",
				padding: "5px",
			}}
			elevation={4}>
			<table>
				<tbody>
					<tr>
						<td>{number}</td>
						<td>
							<ModalImage
								small={`${ADMIN_URL}/photos/${gameID}/${
									withNamesUploaded ? "withNames" : "noNames"
								}/small/${photo.photoID}`}
								large={`${ADMIN_URL}/photos/${gameID}/${
									withNamesUploaded ? "withNames" : "noNames"
								}/${photo.photoID}`}
							/>
						</td>
						<td>
							<table style={{ borderSpacing: "10px" }}>
								<tbody>
									<tr>
										<td style={{ fontSize: "14px" }}>
											Foto geclaimed:
										</td>
										<td style={{ fontSize: "14px" }}>
											Naam geclaimed:
										</td>
									</tr>
									<tr style={{ height: "20px" }}>
										<td>
											<TextField
												id={`photoClaimedBy${photo.photoID}`}
												variant="standard"
												onChange={(event) => {
													setPhotoClaimedBy(
														event.target.value
													);
												}}
												sx={{
													fontSize: "10px",
													width: "60px",
												}}
												value={photoClaimedBy}
											/>
										</td>
										<td>
											<TextField
												id={`nameClaimedBy${photo.photoID}`}
												variant="standard"
												onChange={(event) => {
													setNameClaimedBy(
														event.target.value
													);
												}}
												sx={{
													fontSize: "10px",
													width: "60px",
												}}
												value={nameClaimedBy}
											/>
										</td>
										<td>
											<Button
												variant="contained"
												size="small"
												onClick={() => sendInfo()}>
												<SendIcon />
											</Button>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
		</Paper>
	);
};

export default StreetRow;
