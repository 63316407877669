import StreetRow from "./StreetRow";

const Streets = ({ settings, claimStreet }) => {
	return (
		<div>
			{settings.photos
				? settings.photos.map((photo, i) => {
                        console.log(`With names: ${settings.withNames}`)
						return (
							<StreetRow
								claimPhoto={(info) => claimStreet(info)}
								photo={photo}
								gameID={settings.gameID}
								number={i + 1}
								withNamesUploaded={settings.withNames}
								key={i}
							/>
						);
				  })
				: null}
		</div>
	);
};

export default Streets;
